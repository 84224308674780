// Pagination.js

import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Определение, сколько кнопок показать в пагинации
  const maxButtonsToShow = 5; // Максимальное количество кнопок для отображения
  const halfButtonsToShow = Math.floor(maxButtonsToShow / 2); // Половина от максимального количества

  let startPage, endPage;
  if (pageNumbers.length <= maxButtonsToShow) {
    // Если общее количество страниц меньше или равно максимальному количеству кнопок
    startPage = 1;
    endPage = pageNumbers.length;
  } else if (currentPage <= halfButtonsToShow) {
    // Если текущая страница ближе к началу
    startPage = 1;
    endPage = maxButtonsToShow;
  } else if (currentPage + halfButtonsToShow >= pageNumbers.length) {
    // Если текущая страница ближе к концу
    startPage = pageNumbers.length - maxButtonsToShow + 1;
    endPage = pageNumbers.length;
  } else {
    // Если текущая страница где-то в середине
    startPage = currentPage - halfButtonsToShow;
    endPage = currentPage + halfButtonsToShow;
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.slice(startPage - 1, endPage).map((number) => (
          <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
            <button onClick={() => paginate(number)} className={`page-link ${number === currentPage ? 'active' : ''}`}>
              {number}
            </button>
          </li>
        ))}
        
      </ul>
    </nav>
  );
};

export default Pagination;

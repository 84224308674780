import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Items from "./components/items";
import Categories from "./components/categories";
import ShowFullItem from "./components/ShowFullItem";
import 'font-awesome/css/font-awesome.min.css';
import Pagination from './components/Pagination';
import Form from './components/form';
import items from './components/data';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./AboutUs";
import ContactSection from "./ContactSection";
import ItemsPerPageButtons from './components/ItemsPerPageButtons';
import Modal from 'react-modal';
Modal.setAppElement('#root');
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      currentItems: [],
      searchQuery: '',
      itemsPerPage: 6,
      currentPage: 1,
      items: items,
      ShowFullItem: false,
      fullItem: {},
      showAboutUs: false,
      totalSum: 0,
    }

    this.state.currentItems = this.state.items;
  }

  onShowItem = (item) => {
    this.setState({ fullItem: item, ShowFullItem: true });
  }

  chooseCategory = (category, subcategory) => {
    let filteredItems;

    if (category === 'all') {
      filteredItems = this.state.items;
    } else if (subcategory) {
      filteredItems = this.state.items.filter(
        (el) => el.category === category && el.subcategory === subcategory
      );
    } else {
      filteredItems = this.state.items.filter((el) => el.category === category);
    }

    this.setState({
      currentItems: filteredItems,
      currentPage: 1,
      searchQuery: '',
    });
  };

  handleSearch = () => {
    const { searchQuery, items } = this.state;

    if (searchQuery) {
      const filteredItems = items.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );

      this.setState({
        currentItems: filteredItems,
        currentPage: 1,
      });
    } else {
      this.setState({
        currentItems: this.state.items,
        currentPage: 1,
      });
    }
  };

  deleteOrder = (id) => {
    this.setState({ orders: this.state.orders.filter(el => el.id !== id) });
  }

  addToOrder = (item, quantity) => {
    if (quantity > item.quantity) {
      alert('Нельзя добавить больше товара, чем есть в наличии.');
    } else {
      const orderItem = { ...item, quantity };

      const isItemInCart = this.state.orders.some(order => order.id === item.id);

      if (isItemInCart) {
        // Используем метод openModal для открытия модального окна
        this.openModal();
      } else {
        this.setState({ orders: [...this.state.orders, orderItem] }, () => {
          this.updateTotalPrice();
          console.log('Total price updated:', this.state.totalSum);
        });
      }
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  }

  handleItemsPerPageChange = (newItemsPerPage) => {
    this.setState({
      itemsPerPage: newItemsPerPage,
      currentPage: 1,
    });
  };

  onIncreaseQuantity = (item, quantity, callback) => {
    this.setState(
      (prevState) => ({
        orders: prevState.orders.map((order) =>
          order.id === item.id ? { ...order, quantity: order.quantity + 1 } : order
        ),
      }),
      () => {
        callback();
        this.updateTotalPrice();
      }
    );
  };

  onDecreaseQuantity = (item, quantity, callback) => {
    this.setState(
      (prevState) => ({
        orders: prevState.orders.map((order) =>
          order.id === item.id ? { ...order, quantity: order.quantity - 1 } : order
        ),
      }),
      () => {
        callback();
        this.updateTotalPrice();
      }
    );
  };

  updateTotalPrice = () => {
    let summa = 0;
    this.state.orders.forEach((el) => (summa += el.price * el.quantity));

    this.setState({ totalSum: summa });
  };
  openModal() {
    this.setState({ isModalOpen: true });
  }
  render() {
    const { itemsPerPage, currentPage, currentItems } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsToDisplay = currentItems.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/about"
            element={
              <AboutUs
                orders={this.state.orders}
                onDelete={this.deleteOrder}
              />
            }
          />
          <Route
            path="/contact"
            element={
              <ContactSection
                orders={this.state.orders}
                onDelete={this.deleteOrder}
              />
            }
          />
          <Route
            path="/"
            element={
              <div className="wrapper">
                <Header
                  orders={this.state.orders}
                  onDelete={this.deleteOrder}
                  onDecreaseQuantity={this.onDecreaseQuantity}
                  onIncreaseQuantity={this.onIncreaseQuantity}
                  updateTotalPrice={this.updateTotalPrice}
                />
                <div className="presentation"></div>
                <div className="conten">
                  <div className="search-bar">
                    <input
                      className="inputsearcth"
                      type="text"
                      placeholder="Пошук"
                      value={this.state.searchQuery}
                      onChange={(e) => this.setState({ searchQuery: e.target.value })}
                    />
                    <button className="search-button" onClick={this.handleSearch}>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  <ItemsPerPageButtons onItemsPerPageChange={this.handleItemsPerPageChange} itemsPerPage={this.state.itemsPerPage} />
                </div>

                <div className="content">
                  <div className="categories-and-items">
                    <Categories chooseCategory={this.chooseCategory} />

                    <div className="items-container">
                      <Items onShowItem={this.onShowItem} items={currentItemsToDisplay} onAdd={this.addToOrder}></Items>
                    </div>
                  </div>
                </div>

                {this.state.ShowFullItem && (
                  <ShowFullItem onAdd={this.addToOrder} onShowItem={this.onShowItem} item={this.state.fullItem}></ShowFullItem>
                )}

                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={currentItems.length}
                  currentPage={currentPage}
                  paginate={this.handlePageChange}
                  onItemsPerPageChange={this.handleItemsPerPageChange}
                />

                <Footer></Footer>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;

import React, { useState } from "react";
import { FaShoppingBag, FaBars,  FaTimes} from "react-icons/fa";
import Order from './order'; // Правильное имя компонента Order
import Form from './form'; // Правильное имя компонента Form
import { Link } from "react-router-dom";

export default function Header(props) {
  const [cartOpen, setCartOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    console.log("Mobile Menu Open:", mobileMenuOpen);
  };

  const mobileMenuStyles = {
    display: mobileMenuOpen ? "block" : "none",
    position: "absolute",
    top: "44px", // Вы можете изменить значение в зависимости от вашего дизайна
    right: "-18px",
    backgroundColor: "#fff", // Цвет фона меню
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Тень для стиля
    padding: "20px",
    color: "#000", 
    zIndex: "999",
  };
  
  
  const updateTotalPrice = () => {
    let summa = 0;
    props.orders.forEach((el) => (summa += el.price * el.quantity));
    props.updateTotalPrice(summa);
  };
  
  
  
  const onIncreaseQuantity = (item, quantity) => {
    props.onIncreaseQuantity(item, quantity, () => updateTotalPrice());
  };
  
  const onDecreaseQuantity = (item, quantity) => {
    props.onDecreaseQuantity(item, quantity, () => updateTotalPrice());
  };
  
  
  
  const showOrder = (orders) => {
    let summa = 0;
  
    orders.forEach((el) => (summa += parseFloat(el.price) * el.quantity));
  
    return (
      <div>
        <div className='scroll'>
          {orders.map((el) => (
            <Order
              onDelete={props.onDelete}
              onIncreaseQuantity={onIncreaseQuantity}
              onDecreaseQuantity={onDecreaseQuantity}
              key={el.id}
              item={el}
            />
          ))}
        </div>
        <p className="summa">Сума: {new Intl.NumberFormat().format(summa)}грн</p>
  
        <Form orders={props.orders} totalSum={summa} updateTotalPrice={props.updateTotalPrice} />


      </div>
    );
  };
  

  const showNothing = () => {
    return (
      <div className="empty">
        <h2>Товара немає</h2>
      </div>
    );
  };

  return (
    <header>
    <div>
    <span className="logo"><Link to="/">Magic</Link></span>
      <FaShoppingBag
        onClick={() => setCartOpen(!cartOpen)}
        className={`shop-cart-button ${cartOpen && 'active'}`}
      />
      <FaBars
        className="mobile-menu-button"
        onClick={toggleMobileMenu}
      />

{mobileMenuOpen && (
          <div className="mobile-menu" style={mobileMenuStyles}>
            <ul>
            <li><a href="tel:+380972126391">38097-212-6391</a></li>
              <li>
                <Link to="/about" className="lil">
                  Про нас
                </Link>
              </li>
              <li>
                <Link to="/" className="lil">
                  Магазин
                </Link>
              </li>
              <li>
                <Link to="/contact" className="lil">
                  Контакты
                </Link>
              </li>
            </ul>
          </div>
        )}


      <ul className="nav">
      <li><a href="tel:+380972126391">097-212-6391</a></li>
        <li>
          <Link to="/about" className="lil">
            Про нас
          </Link>
        </li>
        <li>
          <Link to="/" className="lil">
            Магазин
          </Link>
        </li>
        <li>
          <Link to="/contact" className="lil">
            Контакти
          </Link>
        </li>
      </ul>

      {cartOpen && (
        <div className="shop-cart">
          {props.orders.length > 0 ? showOrder(props.orders) : showNothing()}
        </div>
      )}
    </div>
  </header>
  );
}

import React, { Component } from 'react';
import axios from 'axios';
import NovaPoshtaApi from './NovaPoshtaApi';
import CartComponent from './CartComponent';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      selectedArea: '',
      selectedCity: '',
      selectedWarehouse: '',
      selectedPaymentMethod: '',
    };
  }

  componentDidMount() {
    // При монтировании компонента, попробуем извлечь данные из localStorage
    const storedFormData = localStorage.getItem('formData');
    if (storedFormData) {
      this.setState(JSON.parse(storedFormData));
    }
  }

  componentDidUpdate() {
    // При изменении formData, сохраним его в localStorage
    localStorage.setItem('formData', JSON.stringify(this.state));
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      orders,
      totalSum,
      updateTotalPrice,
    } = this.props;

    const {
      name,
      phone,
      selectedArea,
      selectedCity,
      selectedWarehouse,
      selectedPaymentMethod,
    } = this.state;

    // Проверка на пустоту полей
    if (!name || !phone || !selectedArea || !selectedCity || !selectedWarehouse || !selectedPaymentMethod) {
      alert('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    // Проверка формата номера телефона
    const phoneRegex = /^\d{10,12}$/;
    if (!phoneRegex.test(phone)) {
      alert('Номер телефона должен содержать от 10 до 12 цифр.');
      return;
    }

    // Проверка длины имени и фамилии
    if (name.length < 4) {
      alert('Имя и фамилия должны содержать не менее 4 символов.');
      return;
    }
    if (totalSum < 200) {
      alert('Минимальная сумма заказа - 200 гривен.');
      return;
    }

    try {
      const botToken = '6152805514:AAFJ9jTVDg56ENlYHpC8GGntSgSt-54Tpvo';
      const chatId = '-954415821';

      let offer = '';
      orders.forEach((i) => {
        offer += `\n------------------------\nНазвание: ${i.title} \nКатегория: ${i.category} \nЦена: ${i.price} \nКод: ${i.id}\nКоличество: ${i.quantity}\n`;
      });

      const message = `Имя: ${name}\nТелефон: ${phone}\nЗаказ: ${offer} \nОбщая сумма: ${totalSum} грн\nОбласть: ${selectedArea}\nГород: ${selectedCity}\nОтделение: ${selectedWarehouse}\nСпособ оплаты: ${selectedPaymentMethod}`;

      await axios.get(
        `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}`
      );

      alert('Замовляння відправлено на обробку, чекайте, гарного дня!');
      this.setState({
        name: '',
        phone: '',
        selectedArea: '',
        selectedCity: '',
        selectedWarehouse: '',
        selectedPaymentMethod: '',
      });

      updateTotalPrice();
      window.location.reload();

    } catch (error) {
      console.error('Ошибка при отправке сообщения в Telegram:', error);
    }
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Функции для обработки изменений из CartComponent
  handleSelectArea = (selectedArea) => {
    this.setState({ selectedArea });
  };

  handleSelectCity = (selectedCity) => {
    this.setState({ selectedCity });
  };

  handleSelectWarehouse = (selectedWarehouse) => {
    this.setState({ selectedWarehouse });
  };

  handleSelectPaymentMethod = (selectedPaymentMethod) => {
    this.setState({ selectedPaymentMethod });
  };

  render() {
    const { totalSum } = this.props;
    const isButtonDisabled = totalSum < 200;

    return (
      <div>
        <form onSubmit={this.handleSubmit} className="telegram-form">
          {isButtonDisabled && (
            <div className="error-message">
              Минимальная сумма заказа - 200 гривен.
            </div>
          )}

          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Имя и фамилия"
            autoComplete="off"
            value={this.state.name}
            onChange={this.handleInputChange}
          />
          <input
            type="text"
            name="phone"
            className="form-control"
            placeholder="Ваш номер телефона"
            value={this.state.phone}
            onChange={this.handleInputChange}
          />

          <CartComponent
            onSelectArea={this.handleSelectArea}
            onSelectCity={this.handleSelectCity}
            onSelectWarehouse={this.handleSelectWarehouse}
            onSelectPaymentMethod={this.handleSelectPaymentMethod}
          />

          <button
            type="submit"
            className={`get-items-length zakaz ${
              isButtonDisabled ? 'disabled' : ''
            }`}
            disabled={isButtonDisabled}
          >
            Замовити
          </button>
        </form>
      </div>
    );
  }
}

export default Form;

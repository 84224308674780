import React, { Component } from 'react';
import Modal from 'react-modal';
class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      isModalOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    // Проверяем, был ли товар добавлен в корзину при монтировании компонента
    const { item, orders } = this.props;
    const isItemInCart =
      orders && orders.length > 0 && orders.some((order) => order.id === item.id);

    if (isItemInCart) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  handleAddToCart = () => {
    const { item, onAdd } = this.props;
    const { quantity } = this.state;

    onAdd(item, quantity);
    this.openModal();
  };

  decrementQuantity = () => {
    this.setState((prevState) => ({
      quantity: Math.max(prevState.quantity - 1, 1),
    }));
  };

  incrementQuantity = () => {
    const { item } = this.props;
    this.setState((prevState) => ({
      quantity: Math.min(prevState.quantity + 1, item.quantity),
    }));
  };

  render() {
    const { item } = this.props;
    const { quantity } = this.state;

    return (
      <div className="item">
        <img src={"./img/" + item.img} />
        <h2>{item.title}</h2>
        <p>
          {this.props.item.desc} <b>
            <br />Код товара: {this.props.item.id}
          </b>{' '}
        </p>
        <p>Доступное количество: {item.quantity}</p>
        <div className="quantity-control">
          <span>Кількість</span>
          <button onClick={this.decrementQuantity}>-</button>
          <span>{quantity}</span>
          <button onClick={this.incrementQuantity}>+</button>
          <b className="pricec">
            {item.price}грн
            <div className="add-to-cart" onClick={this.handleAddToCart}>
              +
            </div>
          </b>
        </div>

        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Item Added Modal"
          className="ReactModal__Content" // Применяем стили к модальному окну
          overlayClassName="ReactModal__Overlay" // Применяем стили к подложке
        >
          {/* Кнопка закрытия модального окна (красный крестик) */}
          <div
            className="ReactModal__CloseButton"
            onClick={this.closeModal}
          >
            &times;
          </div>
          {this.state.isItemAdded ? (
            <div>
              <p>Товар успешно добавлен в корзину!</p>
              <button onClick={this.closeModal}>Закрыть</button>
            </div>
          ) : (
            <div>
              <p>Товар доданий до корзини.</p>
              
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default Item;

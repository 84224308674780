import React, { useState, useEffect } from 'react';
import NovaPoshtaApi from './NovaPoshtaApi';

const CartComponent = ({
    onSelectArea,
    onSelectCity,
    onSelectWarehouse,
    onSelectPaymentMethod,
  }) => {
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([
    'Наложенный платеж',
    'Оплата картой',
  ]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  useEffect(() => {
    NovaPoshtaApi.getAreas()
      .then((response) => {
        setAreas(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching areas:', error);
      });
  }, []);

  const handleAreaChange = (selectedAreaRef) => {
    const selectedAreaText = areas.find((area) => area.Ref === selectedAreaRef)?.Description || '';
    setSelectedArea(selectedAreaRef);
    onSelectArea(selectedAreaText);

    NovaPoshtaApi.getCities(selectedAreaRef)
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching cities:', error);
      });
  };

  const handleCityChange = (selectedCityRef) => {
    const selectedCityText = cities.find((city) => city.Ref === selectedCityRef)?.Description || '';
    setSelectedCity(selectedCityRef);
    onSelectCity(selectedCityText);

    NovaPoshtaApi.getWarehouses(selectedCityRef)
      .then((response) => {
        setWarehouses(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching warehouses:', error);
      });
  };

  const handleWarehouseChange = (selectedWarehouseRef) => {
    const selectedWarehouseText = warehouses.find((warehouse) => warehouse.SiteKey === selectedWarehouseRef)?.Description || '';
    setSelectedWarehouse(selectedWarehouseRef);
    onSelectWarehouse(selectedWarehouseText);
  }

  const handlePaymentMethodChange = (selectedPaymentMethod) => {
    setSelectedPaymentMethod(selectedPaymentMethod);
    onSelectPaymentMethod(selectedPaymentMethod);
  };

  return (
    <div>
      <div>
      <select value={selectedArea} onChange={(e) => handleAreaChange(e.target.value)}>
          <option value="" disabled selected>
            Выберите область
          </option>
          {areas.map((area) => (
            <option key={area.Ref} value={area.Ref}>
              {area.Description}
            </option>
          ))}
        </select>
      </div>

      {selectedArea && (
        <div>
          <select value={selectedCity} onChange={(e) => handleCityChange(e.target.value)}>

            <option value="" disabled selected>
              Выберите город
            </option>
            {cities.map((city) => (
              <option key={city.Ref} value={city.Ref}>
                {city.Description}
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedCity && warehouses.length > 0 && (
        <div>
         <select value={selectedWarehouse} onChange={(e) => handleWarehouseChange(e.target.value)}>
        <option value="" disabled selected>
              Выберите відділення
            </option>
            {warehouses.map((warehouse) => (
              <option key={warehouse.SiteKey} value={warehouse.SiteKey}>
                {warehouse.Description}
              </option>
            ))}
          </select>
        </div>
      )}

      <div>
        <select value={selectedPaymentMethod} onChange={(e) => handlePaymentMethodChange(e.target.value)}>
    <option value="" disabled selected>
            Выберите способ оплаты
          </option>
          {paymentMethods.map((method) => (
            <option key={method} value={method}>
              {method}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CartComponent;

import React from "react";
import { FaMapMarker, FaPhone, FaEnvelope, FaClock, FaInstagram, FaFacebook } from "react-icons/fa";
import './ContactSection.css'; // Подключаем файл со стилями
import Header from "./components/header";
import Footer from "./components/footer";
function ContactSection ({ orders, onDelete}) {
  return (
    <div className="wrapper">
       <Header orders={orders} onDelete={onDelete} />
       <hr className="menu-line" />
      {/* 1. Карта с маркерами */}
      <div className="olozkaa">
        
      <div className="contentt">
      <div className="contentt-blockk">
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10572.269814476495!2d34.612803!3d48.5127716!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbdba3127c1e03%3A0xac57c332f0a29362!2z0JzQsNCz0LjRjyDRgNGD0LrQvtC00LXQu9C40Y8!5e0!3m2!1suk!2sua!4v1699955586714!5m2!1suk!2sua"
       width="100%"
        height="100%" 
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      </div>
      <div className="contentt-blockk">
<div className="contact-info">
<img className="imgmap" src="./img/map.png"></img>
          <div>
          <FaMapMarker />
          <p>Ваш адрес магазина</p>
        </div>
        <div>
          <FaPhone />
          <p>Телефон:<a href="tel:+380972126391"> 097-212-6391</a></p>
        </div>
        <div>
          <FaEnvelope />
          <p>Email: nitka@mail.com</p>
        </div>
        <div>
          <FaClock />
          <p>График работы: Пн-cб 9:00-17:00</p>
        </div>
        </div>
       <div>
        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
          <FaInstagram  className="instfb"/>
        </a>
        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="instfb"/>
        </a>
      </div>

      </div>
      <div className="contentt-blockk">
      <img className="imgbox" src="./img/box.png"></img>
      <div>
      ДОСТАВКА І ОПЛАТА<br/>
Способи доставки:<br/>
— «Нова пошта»<br/>
— «Укрпошта»<br/>
— Самовивіз із магазину «Магія Рукоділля»<br/>
<br/><br/>
Способи оплати:<br/>
— Картка ПриватБанка<br/>
— Готівкою при отриманні з пункту видачі замовлень <br/><br/>«Магія Рукоділля»
— Готівкою при отриманні у відділенні перевізника<br/>

Якщо замовлення залишається неоплаченим протягом 3-х днів — воно анулюється.<br/>
</div>
</div>
      </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ContactSection;

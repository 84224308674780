import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import './about.css'
function AboutUs({ orders, onDelete }) {
  return (
    <div className="wrapper">
      <Header orders={orders} onDelete={onDelete} />
      <hr className="menu-line" />
      <div className="olozka">
        <div className="info-block">
          <h3>Магазин Рукоділля</h3>
          <h1>Інформація про нас</h1>
        </div>
        <div className="contentt">
          <div className="contentt-block">
            <h2>Чим ми займаємося</h2>
            <img src="./img/store.jpg" alt="Фото 1" />
            <p>Роздрібні та оптовими продажем: ниток, голок, картин, багетів, пряжі, бусинок, та все що треба, щоб творити магія своїми руками</p>
          </div>
          <div className="contentt-block">
            <h2>Де нас знайти</h2>
            <img src="./img/local.jpg" alt="Фото 2" />
            <p>Ми знаходимося за адресою: проспект Свободи, 57в, Кам’янське, Дніпропетровська область</p>
          </div>
          <div className="contentt-block">
            <h2>Чому саме ми</h2>
            <img src="./img/estetik.jpg" alt="Фото 3" />
            <p>Ми відправляємо наш товар по всій Україні, в нас великий асортимент товару, підтримка 24/7, та великі бонуси</p>
          </div>
        </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default AboutUs;

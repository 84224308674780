import React, { Component } from 'react';
import { FaTrash } from 'react-icons/fa';

class Order extends Component {
  handleIncreaseQuantity = () => {
    const { item, onIncreaseQuantity } = this.props;
    onIncreaseQuantity(item);
  };

  handleDecreaseQuantity = () => {
    const { item, onDecreaseQuantity } = this.props;
    onDecreaseQuantity(item);
  };

  render() {
    const { item } = this.props;

    return (
      <div className="item">
        <img src={"./img/" + item.img} alt={item.title} />
        <h2>{item.title}</h2>
        <div className="quantity-control">
          <b>{item.price}грн</b>
          <button onClick={this.handleDecreaseQuantity}>-</button>
          <span>{item.quantity}</span>
          <button onClick={this.handleIncreaseQuantity}>+</button>
          <FaTrash className="deleteItem" onClick={() => this.props.onDelete(item.id)} />
        </div>
      </div>
    );
  }
}

export default Order;

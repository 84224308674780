import React from 'react';

const ItemsPerPageButtons = ({ onItemsPerPageChange, itemsPerPage }) => {
  return (
    <li className='items-per-page-list'>
      <span>Товаров на странице:</span>
      <button onClick={() => onItemsPerPageChange(6)} className={`items-per-page-button ${itemsPerPage === 6 ? 'active' : ''}`}>
        6
      </button>
      <button onClick={() => onItemsPerPageChange(9)} className={`items-per-page-button ${itemsPerPage === 9 ? 'active' : ''}`}>
        9
      </button>
      <button onClick={() => onItemsPerPageChange(12)} className={`items-per-page-button ${itemsPerPage === 12 ? 'active' : ''}`}>
        12
      </button>
      <button onClick={() => onItemsPerPageChange(24)} className={`items-per-page-button ${itemsPerPage === 24 ? 'active' : ''}`}>
        24
      </button>
    </li>
  );
};

export default ItemsPerPageButtons;

import React, { Component } from 'react';

class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [
                {
                    key: 'all',
                    name: 'Всі',
                    subcategories: [],
                },
                {
                    key: 'Pryzha',
                    name: 'Пряжа',
                    subcategories: [
                        {
                            key: 'Akril',
                            name: 'Макроме',
                        },
                        {
                            key: 'Plush',
                            name: 'Плюш',
                        },
                        {
                            key: 'polushert',
                            name: 'Полушерсть',
                        },
                        {
                            key: 'triko',
                            name: 'Трикотажная',
                        },
                        {
                            key: 'hlopchatbumag',
                            name: 'Хлопчато бумажна',
                        },
                        {
                            key: 'Makrome',
                            name: 'Акрил',
                        },
                         {
                            key: 'Mohair',
                            name: 'Махер',
                        },
                        
                    ],
                },
                {
                    key: 'Instrument',
                    name: 'Інструмент',
                    subcategories: [
                        {
                            key: 'Igla',
                            name: 'Голкі',
                        },
                         {
                            key: 'Noznichi',
                            name: 'Ножиці',
                        },
                         {
                            key: 'Spitca',
                            name: 'Спиці',
                        },
                         {
                            key: 'Kruchki',
                            name: 'Гачки',
                        },
                        {
                            key: 'pyalza',
                            name: 'пяльца',
                        },
                        {
                            key: 'kysachki',
                            name: 'кусачкі',
                        },


                    ],
                },
                {
                    key: 'shem',
                    name: 'Схеми для вишиванія',
                    subcategories: [
                       {
                            key: 'flizelin',
                            name: 'флізелін',
                        },
                        
                        {
                            key: 'biserni shemi A3',
                            name: 'бісерні схеми А3',
                        },
                         {
                            key: 'biserni shemi A4',
                            name: 'бісерні схеми А4',
                        },







                         ],

                },
                 {
                    key: 'biser',
                    name: 'бісер',
                    subcategories: [
                       {
                            key: 'cheskiy',
                            name: 'чешський бісер',
                        },
                         {
                            key: 'cheskiy',
                            name: 'кітайський бісер',
                        },



                         ],

                },
                 {
                    key: 'inshe',
                    name: ' інше',
                    subcategories: [
                       {
                            key: 'gofra',
                            name: 'гофра бумага',
                        },
                         {
                            key: 'organayzer',
                            name: 'органайзер',
                        },
                        {
                            key: 'fetr',
                            name: 'фетр',
                        },
                        {
                            key: 'foamiran',
                            name: 'фоаміран',
                        },
                        {
                            key: 'provolka',
                            name: 'проволока',
                        },
                        {
                            key: 'rizne',
                            name: 'різне',
                        },
                        {
                            key: 'cord',
                            name: 'шнури',
                        },
                         {
                            key: 'fyrnityra',
                            name: 'фурнітура',
                        },
                        {
                            key: 'godinik',
                            name: 'годинники',
                        },
                        {
                            key: 'teyp',
                            name: 'тейплента',
                        },
                         {
                            key: 'nitki',
                            name: 'нитки',
                        },
                        {
                            key: 'rezinka',
                            name: 'резінка',
                        },

                        
                        




                         ],

                },

            ],
            activeCategory: 'all',
            activeSubcategory: null,
            mobileMenuOpen: false,
            isSubMenuOpen: false,
            isCloseButtonVisible: false,
            isDesktopView: window.innerWidth > 730,
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            isDesktopView: window.innerWidth > 730,
        });
    };
    toggleCategory(categoryKey, subcategoryKey = null) {
        this.setState({
            activeCategory: categoryKey,
            activeSubcategory: subcategoryKey,
        });
        
        this.props.chooseCategory(categoryKey, subcategoryKey);
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            isMenuOpen: !prevState.isMenuOpen,
        }));
    };

    renderSubcategories(subcategories) {
        if (subcategories && subcategories.length > 0) {
            return (
                <ul className="subcategories">
                    {subcategories.map(subcategory => (
                        <li
                            key={subcategory.key}
                            onClick={() => {
                                this.toggleCategory(this.state.activeCategory, subcategory.key);
                                // Закрываем меню при выборе подкатегории
                                this.toggleMenu();
                            }}
                            className={this.state.activeSubcategory === subcategory.key ? 'active' : ''}
                        >
                            {subcategory.name}
                        </li>
                    ))}
                </ul>
            );
        }
        return null;
    }


    renderCategories() {
        return this.state.categories.map(category => {
            return (
                <div key={category.key} className={`category ${this.state.activeCategory === category.key ? 'active' : ''}`}>
                    <div onClick={() => this.toggleCategory(category.key)}>{category.name}</div>
                    {this.renderSubcategories(category.subcategories)}
                </div>
            );
        });
    }

    render() {
        const { isDesktopView } = this.state;

        if (isDesktopView) {
            // Рендер компьютерной версии
            return (
                <div className="categories">
                    <div className="desktop-categoriess">{this.renderCategories()}</div>
                </div>
            );
        }

        // Рендер мобильной версии
        return (
            <div className="categories">
                <button className="mobilee-menu-button" onClick={this.toggleMenu}>
                    Меню
                </button>
                {this.state.isMenuOpen && <div className="mobilee-menu">{this.renderCategories()}</div>}
            </div>
        );
    }
}

export default Categories;
// NovaPoshtaApi.js
import axios from 'axios';

const NovaPoshtaApi = {
  baseUrl: 'https://api.novaposhta.ua/v2.0/json/',
  apiKey: '08a687498f88b7a41e97fdc5dbba8bff', // Замените на ваш API-ключ

  getCities: (areaRef) => {
    const data = {
      apiKey: NovaPoshtaApi.apiKey,
      modelName: 'Address',
      calledMethod: 'getCities',
      methodProperties: {
        AreaRef: areaRef,
      },
    };

    return axios.post(NovaPoshtaApi.baseUrl, data);
  },

  getStreets: (cityRef) => {
    const data = {
      apiKey: NovaPoshtaApi.apiKey,
      modelName: 'Address',
      calledMethod: 'getStreet',
      methodProperties: {
        CityRef: cityRef,
      },
    };

    return axios.post(NovaPoshtaApi.baseUrl, data);
  },

  getWarehouses: (cityRef) => {
    const data = {
      apiKey: NovaPoshtaApi.apiKey,
      modelName: 'Address',
      calledMethod: 'getWarehouses',
      methodProperties: {
        CityRef: cityRef,
      },
    };

    return axios.post(NovaPoshtaApi.baseUrl, data);
  },
  getAreas: () => {
    const data = {
      apiKey: NovaPoshtaApi.apiKey,
      modelName: 'Address',
      calledMethod: 'getAreas',
      methodProperties: {},
    };

    return axios.post(NovaPoshtaApi.baseUrl, data);
  },
};

export default NovaPoshtaApi;
